<template>
  <v-sheet elevation="2" class="device-sidebar">
    <v-subheader v-if="!hasLoaded">
      <v-progress-circular indeterminate/>
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        Close
      </v-tooltip>
    </v-subheader>
    <template v-else-if="device">
      <v-toolbar elevation="0">
        <v-toolbar-title>{{ device.title }}</v-toolbar-title>
        <v-spacer/>
        <v-tooltip bottom v-if="device.parentLocationId">
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="$router.push({name:'site-map-device',params:{id:device.uuid}})">
              <v-icon>mdi-map-marker</v-icon>
            </v-btn>
          </template>
          Show on Map
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="$root.$emit('editDevice',device)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          Edit
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="$root.$emit('printLabels',[device])">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          Print Label
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          Close
        </v-tooltip>
      </v-toolbar>
      <v-container>
        <v-banner :value="device.notLabelReady" outlined single-line>
          <v-icon left color="warning">mdi-alert</v-icon>
          This device is missing information: {{ device.notLabelReadySummary }}
        </v-banner>
        <div>{{ device.description }}</div>
        <dl>
          <dt>Location</dt>
          <dd>{{ device.location || '-' }}</dd>
          <dt/>
          <dd>{{ device.parentLocation || '-' }}</dd>

          <dt>Coordinates (x,y,z)</dt>
          <dd>
            {{ device.planLocation.centre.x.toFixed(2) || '-' }},
            {{ device.planLocation.centre.y.toFixed(2) || '-' }},
            {{ device.planLocation.centre.z.toFixed(2) || '-' }}
          </dd>

          <dt>Type</dt>
          <dd>{{ device.type || '-' }}</dd>

          <dt>Manufacturer</dt>
          <dd>{{ device.productManufacturer || '-' }}</dd>

          <dt>Model</dt>
          <dd>{{ device.productModel || '-' }}</dd>

          <dt>Date of Manufacture</dt>
          <dd>{{ device.manufactureDateString || '-' }}</dd>

          <dt>Label Type</dt>
          <dd v-if="device._labelType">{{ device._labelType.title + ': ' + device._labelType.description }}</dd>
          <dd v-else>-</dd>

          <dt>Labelled</dt>
          <dd>{{ device.labelledDateString || '-' }}</dd>

          <dt>Installed</dt>
          <dd>{{ device.installationDateString || '-' }}</dd>

          <dt>Serial Number</dt>
          <dd>{{ device.serialNumber || '-' }}</dd>

          <dt>Hardware Version</dt>
          <dd>{{ device.hardwareVersion || '-' }}</dd>

          <dt>Software Version</dt>
          <dd>{{ device.softwareVersion || '-' }}</dd>

          <dt>Firmware Version</dt>
          <dd>{{ device.firmwareVersion || '-' }}</dd>

          <dt>UUID</dt>
          <dd>{{ device.uuid || '-' }}</dd>
        </dl>
        <template v-if="device.aliases">
          <h4 class="mt-2">Device Aliases</h4>
          <dl v-for="(alias, name) in device.aliases" :key="name">
            <dt :key="name">{{ camelToSentence(name) }}</dt>
            <dd :key="alias">{{ alias }}</dd>
          </dl>
        </template>
        <template v-if="device.nics">
          <h4 class="mt-2">Network Interfaces</h4>
          <template v-for="(nic, i) in networkInterfaces">
            <h5 :key="i + 'label'" class="px-0">{{ nic.name }}</h5>
            <dl :key="i">
              <template v-if="nic.ip">
                <dt>IP Address</dt>
                <dd>{{ nic.ip }}</dd>
              </template>
              <template v-if="nic.mac">
                <dt>MAC Address</dt>
                <dd>{{ nic.mac }}</dd>
              </template>
            </dl>
          </template>
        </template>
      </v-container>
    </template>
    <v-subheader v-else>
      Device {{ id }} not found
      <v-spacer/>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        Close
      </v-tooltip>
    </v-subheader>
  </v-sheet>
</template>

<script>
import {camelToSentence} from '@/util/string';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'DeviceSidebar',
  computed: {
    ...mapGetters('site/devices/selected', ['selectedDevice', 'loaded']),
    id() {
      return this.$route.params.id;
    },
    hasLoaded() {
      return this.loaded || this.selectedDevice;
    },
    device() {
      return this.selectedDevice;
    },
    networkInterfaces() {
      if (!this.device || !this.device.nics) {
        return [];
      }
      return Object.keys(this.device.nics)
          .sort()
          .map(name => {
            return {
              name,
              ...this.device.nics[name]
            };
          });
    }
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        this.bind(id).catch(e => this.$logger.debug('bind error', e));
      }
    }
  },
  beforeDestroy() {
    this.unbind().catch(e => this.$logger.debug('unbind error', e));
  },
  methods: {
    camelToSentence,
    ...mapActions('site/devices/selected', [
      'bind',
      'unbind'
    ]),
    close() {
      if (this.$route.name === 'site-device') {
        this.$router.push({name: 'site-devices'});
      } else if (this.$route.name === 'site-map-device') {
        this.$router.push({name: 'site-map'});
      }
    }
  }
};
</script>

<style scoped>
.device-sidebar {
  --toolbar-height: 64px;
  position: fixed;
  top: var(--toolbar-height);
  right: 0;
  height: calc(100vh - var(--toolbar-height));
  width: 600px;
  overflow-y: auto;
}

@media (max-width: 800px) {
  .device-sidebar {
    --toolbar-height: 56px;
    left: 0;
    width: 100vw;
  }
}

dl {
  display: grid;
  grid-template-columns: auto 1fr;
}

dt {
  margin-right: 4px;
}

dt, dd {
  min-height: 24px;
  line-height: 24px;
}

dt {
  color: #999;
  width: 150px;
  text-transform: uppercase;
  font-size: 0.8em;
  /*line-height: 1.2em;*/
}

.v-subheader {
  align-items: end;
  height: 36px;
}
</style>
