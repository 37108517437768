<template>
  <div class="text-caption grey--text text--lighten-1 text-center">
    © Vanti Ltd. | {{ versionName }}
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'AboutLine',
  computed: {
    ...mapGetters('versions', ['versionName'])
  }
};
</script>

<style scoped>

</style>
